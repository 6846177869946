define("discourse/plugins/discourse-user-card-badges/discourse/controllers/preferences-card-badge", ["exports", "@ember/controller", "@ember/object", "@ember/service", "discourse/lib/ajax", "discourse/models/badge", "discourse-common/utils/decorators", "I18n"], function (_exports, _controller, _object, _service, _ajax, _badge, _decorators, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _controller.default.extend(dt7948.p({
    dialog: (0, _service.inject)(),
    saving: false,
    saved: false,
    filteredList(model) {
      return model.filterBy("badge.image");
    },
    selectableUserBadges(filteredList) {
      return [_object.default.create({
        badge: _badge.default.create({
          name: _I18n.default.t("badges.none")
        })
      }), ...filteredList.uniqBy("badge.name")];
    },
    savingStatus(saving) {
      return saving ? "saving" : "save";
    },
    selectedUserBadge(selectedUserBadgeId) {
      return this.selectableUserBadges.findBy("id", parseInt(selectedUserBadgeId, 10));
    },
    actions: {
      save() {
        this.setProperties({
          saved: false,
          saving: true
        });
        (0, _ajax.ajax)(`${this.user.path}/preferences/card-badge`, {
          type: "PUT",
          data: {
            user_badge_id: this.selectedUserBadgeId
          }
        }).then(() => {
          this.setProperties({
            saved: true,
            saving: false,
            "user.card_image_badge": this.get("selectedUserBadge.badge.image")
          });
        }).catch(() => {
          this.set("saving", false);
          this.dialog.alert(_I18n.default.t("generic_error"));
        });
      }
    }
  }, [["method", "filteredList", [(0, _decorators.default)("model")]], ["method", "selectableUserBadges", [(0, _decorators.default)("filteredList")]], ["method", "savingStatus", [(0, _decorators.default)("saving")]], ["method", "selectedUserBadge", [(0, _decorators.default)("selectedUserBadgeId")]]]));
});