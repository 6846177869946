define("discourse/plugins/discourse-user-card-badges/discourse/routes/preferences-card-badge", ["exports", "discourse/models/user-badge", "discourse/routes/restricted-user"], function (_exports, _userBadge, _restrictedUser) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _restrictedUser.default.extend({
    showFooter: true,
    model() {
      return _userBadge.default.findByUsername(this.modelFor("user").get("username"));
    },
    setupController(controller, model) {
      controller.set("model", model);
      controller.set("user", this.modelFor("user"));
      model.forEach(function (userBadge) {
        if (userBadge.get("badge.id") === controller.get("user.card_image_badge_id")) {
          controller.set("selectedUserBadgeId", userBadge.get("id"));
        }
      });
    }
  });
});